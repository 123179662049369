<template>
  <div class="bg_main  noscroll">
    <header-formulir
      v-bind:title="'Formulir Alkes Bermerkuri'"
      v-bind:date="getDate"
    />
    <div class="tempIframe">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeaHxC9Xkz8YFV_fTWnQ_nN3z8YnSgfGenpEJvwyUtwAC-4Tw/viewform"
        id="myFrame"
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="50px"
        marginwidth="0"
        >Memuat…</iframe
      >
    </div>
  </div>
</template>

<script>
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
export default {
  name: "alkesBermekrui",
  components: {
    HeaderFormulir,
  },
  computed: {
    getDate() {
      let date = this.$date();
      return date;
    },
  },
  data() {
    return {
      dialogSuccess: false,
      setHeight: null,
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getHeight();
    }, 200);
  },
  methods: {
    getHeight() {
      let iframe = document.getElementById("myFrame");

      this.setHeight = `100vh`;
      console.log(iframe.contentWindow.document.documentElement.scrollHeight);
      console.log(iframe.contentWindow);
    },
  },
};
</script>

<style scoped>
.tempIframe {
    /* height: 100vh; */
    height: 91vh;
    
}
.noscroll {
    height: 100vh;
    overflow: hidden;
}
</style>